/* #region Imports */
import React, { useEffect, useMemo, useRef, useState } from "react";
import * as yup from "yup";
import { graphql, PageProps } from "gatsby";
import { Button, Form, Spinner } from "react-bootstrap";
import { FieldArray, Formik, FormikHelpers } from "formik";
import { getJobIdQueryParamName, getJobPageSlug, Job } from "../types/job";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faCircleExclamation,
  faInfoCircle,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import {
  cellphoneRegex,
  fetchData,
  getAbsolutePageUrl,
  getApiUrl,
  phoneRegex,
  sendFormJson,
} from "../utils";
import SEO from "components/seo";
import Ad from "components/ad";
import _ from "lodash";
/* #endregion */

/* #region Functions */
function getQuestionType(fieldType: string): string {
  const fType = fieldType.substring(0, fieldType.indexOf("#") - 1);

  switch (fType) {
    default:
    case "1":
      return "text";
    case "2":
      return "textarea";
    case "3":
      return "number";
    case "4":
      return "yes/no";
  }
}

function getJobReferenceId() {
  if (window?.location?.search) {
    const queryParams = new URLSearchParams(window.location.search);
    const term = queryParams.get(getJobIdQueryParamName()) ?? undefined;

    return term;
  }
}

async function fetchJobData(url: string, jobId: string) {
  let jobData: Job | undefined;

  await fetchData<typeof jobStoreObj | undefined>(url)
    .then((data) => {
      jobData = data?.[jobId] ?? undefined;
    })
    .catch((error) => {
      jobData = undefined;
    });

  return jobData;
}

function prepareFormValues(values: FormValues, job?: Job | null) {
  let newValues: any = {
    ...values,
    "cellphone-number": values.cellphone.replace(/[^0-9.]/, ""),
    "phone-number": values.phone.replace(/[^0-9.]/, ""),
  };

  if (job) {
    newValues = {
      ...newValues,
      jobId: job.uid,
      jobUrl: getAbsolutePageUrl(getJobPageSlug(job, true)),
    };
  }

  return newValues;
}
/* #endregion */

/* #region Variables */
const resumeFileSizeLimit = 10240; // In KB
const resumeFileFormats = [
  "doc",
  "docx",
  "odt",
  "pdf",
  "txt",
  "jpg",
  "jpeg",
  "png",
  "jfif",
  "tif",
];

const jobStoreObj: { [key: string]: Job } = {};
/* #endregion */

/* #region Types */
interface FormValues {
  name: string;
  email: string;
  cellphone: string;
  phone: string;
  referral: string;
  resume: string;
  questions: { question: string; answer: string }[];
  presentation: string;
  newsletter: boolean;
  terms: boolean;
}

interface SendResumePageProps extends PageProps {
  data: {
    site: {
      siteMetadata: {
        siteName: string;
      };
    };
    localSearchResumePageJobData: {
      publicStoreURL: string;
    };
  };
}
/* #endregion */

const SendResumePage = ({ data, location }: SendResumePageProps) => {
  const { siteMetadata } = data.site;
  const [jobRef, setJobRef] = useState<Job | null>(null);
  const [sendSucceed, setSendSuccess] = useState<boolean | null>(null);
  const [formTouched, setFormTouched] = useState(false);
  const resumeFileInput = useRef<HTMLInputElement | null>(null);

  let initialValues: FormValues = {
    name: "",
    email: "",
    cellphone: "",
    phone: "",
    referral: "",
    resume: "",
    questions: [],
    presentation: "",
    newsletter: false,
    terms: false,
  };

  initialValues = useMemo(() => {
    let values = initialValues;

    values.questions = [];
    jobRef?.userApplyQuestions?.forEach((q) => {
      values.questions.push({ question: q.question, answer: "" });
    });

    return values;
  }, [jobRef]);

  const formSchema = useMemo(
    () =>
      yup.object().shape({
        jobId: yup.string().nullable(),
        name: yup
          .string()
          .required("O nome é obrigatório")
          .min(3, "Nome muito curto"),
        email: yup
          .string()
          .required("O email é obrigatório")
          .email("Digite um email válido"),
        cellphone: yup
          .string()
          .required("O celular/whatsapp é obrigatório")
          .matches(cellphoneRegex, "Digite um celular válido"),
        phone: yup
          .string()
          .notRequired()
          .matches(phoneRegex, "Digite um telefone válido"),
        referral: yup
          .string()
          .required(
            'Campo obrigatório. Digite: "N/A" caso não houver indicação'
          ),
        resume: yup
          .mixed()
          .required("O currículo é obrigatório")
          .test(
            "fileFormat",
            "Formatos aceitos: " + resumeFileFormats.join(", "),
            (value) =>
              value
                ? resumeFileFormats.includes(
                    value.match(/\.[0-9a-z]+$/i)[0]?.slice(1)
                  )
                : true
          )
          .test("fileSize", "Arquivo muito grande", () => {
            return (
              (resumeFileInput.current?.files?.[0]?.size ?? 0) <=
              resumeFileSizeLimit * 1024
            );
          }),
        questions: yup
          .array()
          .of(
            yup.object().shape({
              question: yup.string().required(),
              answer: yup.string().required("A resposta é obrigatória"),
            })
          )
          .min(0),
        presentation: yup
          .string()
          .required("A apresentação é obrigatória")
          .min(100, "Apresentação muito curta"),
        newsletter: yup.bool().required(),
        terms: yup
          .bool()
          .required()
          .oneOf([true], "Aceite os termos para continuar"),
      }),
    [resumeFileInput]
  );

  useEffect(() => {
    const jobRefId = getJobReferenceId();

    if (jobRefId) {
      fetchJobData(
        data.localSearchResumePageJobData.publicStoreURL,
        jobRefId
      ).then((data) => {
        if (data) setJobRef({ ...data, uid: jobRefId } ?? null);
      });
    }
  }, []);

  const formSubmit = (
    values: FormValues,
    formikHelpers: FormikHelpers<FormValues>
  ) => {
    const preparedValues = prepareFormValues(values, jobRef);

    // Set resume file to object instead of string path
    preparedValues.resume = resumeFileInput.current?.files;

    sendFormJson(getApiUrl("/api/forms/send-resume"), preparedValues, true)
      .then((r) => {
        formikHelpers.resetForm();
        setFormTouched(false);
        setSendSuccess(true);
      })
      .catch(() => {
        setSendSuccess(false);
      })
      .finally(() => {
        formikHelpers.setSubmitting(false);
      });
  };

  const formChanged = () => {
    if (sendSucceed) {
      setSendSuccess(null);
    }
  };

  const formBlurred = () => {
    if (!formTouched) {
      setFormTouched(true);
    }
  };

  return (
    <React.Fragment>
      <SEO
        title="Enviar currículo"
        canonicalUrl={getAbsolutePageUrl(location.pathname)}
      />

      <h1 className="card-title mb-3">Enviar currículo (Grátis)</h1>
      {jobRef ? (
        <p>
          Preencha os dados abaixo para concorrer à vaga:
          <br />
          <strong>{jobRef.title}</strong>
        </p>
      ) : (
        <p>
          Preencha os dados abaixo para enviar seu currículo e entraremos em
          contato quando acharmos uma vaga para você!
        </p>
      )}
      <div className="text-white bg-info border rounded border-0 p-3 p-lg-4">
        <div className="pb-2 pb-lg-1">
          <p className="mb-0">
            <FontAwesomeIcon icon={faInfoCircle} className="fs-5 me-3" />
            Seu currículo será enviado diretamente para a{" "}
            {siteMetadata.siteName} através do nosso servidor de e-mail. Você
            receberá uma mensagem de confirmação no e-mail informado.
          </p>
        </div>
      </div>
      <div className="py-3">
        <Formik
          validationSchema={formSchema}
          onSubmit={formSubmit}
          initialValues={initialValues}
          enableReinitialize={true}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            isSubmitting,
            errors,
          }) => (
            <Form
              noValidate
              onSubmit={handleSubmit}
              onChange={formChanged}
              onBlur={formBlurred}
              className="text-start d-grid gap-3"
            >
              <div className="card">
                <div className="card-header">
                  <p className="fs-5 m-0">Informações Pessoais</p>
                </div>
                <div className="card-body">
                  <Form.Group className="mb-3" controlId="formikControl0">
                    <Form.Label>Nome completo:</Form.Label>
                    <Form.Control
                      disabled={isSubmitting}
                      className="shadow-sm"
                      type="text"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.name && !errors.name}
                      isInvalid={touched.name && !!errors.name}
                      placeholder="Ex: João da Silva"
                    />

                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formikControl1">
                    <Form.Label>Email para contato:</Form.Label>
                    <Form.Control
                      disabled={isSubmitting}
                      className="shadow-sm"
                      type="email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.email && !errors.email}
                      isInvalid={touched.email && !!errors.email}
                      placeholder="Ex: joaodasilva@gmail.com"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <Form.Group className="mb-3" controlId="formikControl2">
                        <Form.Label>Celular / Whatsapp:</Form.Label>
                        <Form.Control
                          disabled={isSubmitting}
                          className="shadow-sm"
                          type="tel"
                          name="cellphone"
                          value={values.cellphone}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={touched.cellphone && !errors.cellphone}
                          isInvalid={touched.cellphone && !!errors.cellphone}
                          placeholder="Ex: (99) 9 9999-9999"
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.cellphone}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                    <div className="col-12 col-md-6">
                      <Form.Group className="mb-3" controlId="formikControl3">
                        <Form.Label>Telefone:</Form.Label>
                        <Form.Control
                          disabled={isSubmitting}
                          className="shadow-sm"
                          type="tel"
                          name="phone"
                          value={values.phone}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={
                            touched.phone && !errors.phone && !!values.phone
                          }
                          isInvalid={touched.phone && !!errors.phone}
                          placeholder="Ex: (11) 1111-1111"
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.phone}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <p className="fs-5 m-0">Indicação</p>
                </div>
                <div className="card-body">
                  <Form.Group className="mb-3" controlId="formikControl4">
                    <Form.Label>
                      Se você foi indicado por alguém, digite abaixo o nome da
                      pessoa ou organização. Se você não foi indicado, digite:
                      "N/A" ou onde nos achou.
                    </Form.Label>
                    <Form.Control
                      disabled={isSubmitting}
                      className="shadow-sm"
                      type="text"
                      name="referral"
                      value={values.referral}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.referral && !errors.referral}
                      isInvalid={touched.referral && !!errors.referral}
                      placeholder="Ex: João da Silva"
                    />

                    <Form.Control.Feedback type="invalid">
                      {errors.referral}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <p className="fs-5 m-0">Currículo</p>
                </div>
                <div className="card-body">
                  <Form.Group className="mb-3" controlId="formikControl5">
                    <Form.Label>
                      Anexe seu currículo{" "}
                      <small>
                        (tamanho máximo{" "}
                        {resumeFileSizeLimit >= 1024
                          ? Math.floor(resumeFileSizeLimit / 1024) + "MB"
                          : resumeFileSizeLimit + "KB"}
                        )
                      </small>
                      :
                    </Form.Label>
                    <Form.Control
                      disabled={isSubmitting}
                      ref={resumeFileInput}
                      className="shadow-sm"
                      type="file"
                      name="resume"
                      value={values.resume ?? ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.resume && !errors.resume}
                      isInvalid={touched.resume && !!errors.resume}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.resume}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              </div>
              {jobRef?.userApplyQuestions?.length ? (
                <div className="card">
                  <div className="card-header">
                    <p className="fs-5 m-0">
                      Questionário {"("}elaborado/requerido pelo recrutador
                      {")"}
                    </p>
                  </div>
                  <div className="card-body">
                    <FieldArray name="questions">
                      {() =>
                        jobRef?.userApplyQuestions?.map((q, i) => {
                          const questionErrors: any =
                            (errors.questions?.length && errors.questions[i]) ||
                            {};

                          const questionTouched =
                            (touched.questions?.length &&
                              touched.questions[i]) ||
                            {};

                          const groupProps = {
                            className: "mb-3",
                            controlId: "formikControl" + (6 + i),
                          };

                          const controlProps = {
                            name: `questions.${i}.answer`,
                            onChange: handleChange,
                            onBlur: handleBlur,
                            // isValid:
                            //   questionTouched.answer &&
                            //   !questionErrors.answer,
                            isInvalid:
                              questionTouched.answer && !!questionErrors.answer,
                          };

                          const hiddenControlProps = {
                            name: `questions.${i}.question`,
                            type: "hidden",
                          };

                          switch (getQuestionType(q.fieldType)) {
                            default:
                            case "text":
                              return (
                                <React.Fragment key={i}>
                                  <Form.Control
                                    {...hiddenControlProps}
                                    value={values.questions[i]?.question || ""}
                                  />
                                  <Form.Group {...groupProps}>
                                    <Form.Label>{q.question}</Form.Label>
                                    <Form.Control
                                      {...controlProps}
                                      disabled={isSubmitting}
                                      className="shadow-sm"
                                      type="text"
                                      value={values.questions[i]?.answer || ""}
                                      placeholder="Digite sua resposta..."
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {questionErrors.answer}
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                </React.Fragment>
                              );
                            case "textarea":
                              return (
                                <React.Fragment key={i}>
                                  <Form.Control
                                    {...hiddenControlProps}
                                    value={values.questions[i]?.question || ""}
                                  />
                                  <Form.Group {...groupProps}>
                                    <Form.Label>{q.question}</Form.Label>
                                    <Form.Control
                                      {...controlProps}
                                      disabled={isSubmitting}
                                      className="shadow-sm"
                                      as="textarea"
                                      value={values.questions[i]?.answer || ""}
                                      rows={4}
                                      placeholder="Digite sua resposta..."
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {questionErrors.answer}
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                </React.Fragment>
                              );
                            case "number":
                              return (
                                <React.Fragment key={i}>
                                  <Form.Control
                                    {...hiddenControlProps}
                                    value={values.questions[i]?.question || ""}
                                  />
                                  <Form.Group {...groupProps}>
                                    <Form.Label>{q.question}</Form.Label>
                                    <Form.Control
                                      {...controlProps}
                                      disabled={isSubmitting}
                                      className="shadow-sm"
                                      type="number"
                                      value={values.questions[i]?.answer || ""}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {questionErrors.answer}
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                </React.Fragment>
                              );
                            case "yes/no":
                              return (
                                <React.Fragment key={i}>
                                  <Form.Control
                                    {...hiddenControlProps}
                                    value={values.questions[i]?.question || ""}
                                  />
                                  <Form.Group {...groupProps}>
                                    <div className="mb-1">{q.question}</div>
                                    <Form.Check
                                      {...controlProps}
                                      disabled={isSubmitting}
                                      required
                                      inline
                                      id={`inline-questions.${i}.answer-1`}
                                      type="radio"
                                      label="Sim"
                                      value="Sim"
                                      checked={
                                        values.questions[i]?.answer === "Sim"
                                      }
                                    />
                                    <Form.Check
                                      {...controlProps}
                                      disabled={isSubmitting}
                                      required
                                      inline
                                      id={`inline-questions.${i}.answer-2`}
                                      type="radio"
                                      label="Não"
                                      value="Não"
                                      checked={
                                        values.questions[i]?.answer === "Não"
                                      }
                                    />
                                    <Form.Control.Feedback
                                      type="invalid"
                                      style={{
                                        display: controlProps.isInvalid
                                          ? "block"
                                          : "none",
                                      }}
                                    >
                                      {questionErrors.answer}
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                </React.Fragment>
                              );
                          }
                        })
                      }
                    </FieldArray>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="card">
                <div className="card-header">
                  <p className="fs-5 m-0">Apresentação</p>
                </div>
                <div className="card-body">
                  <Form.Group
                    className="mb-3"
                    controlId={
                      "formikControl" +
                      (6 + (jobRef?.userApplyQuestions?.length ?? 0))
                    }
                  >
                    <Form.Label>
                      Explique ao recrutador de que forma sua qualificação
                      atende aos requisitos da vaga e porque você é o melhor
                      candidato para preenchê-la.
                    </Form.Label>
                    <Form.Control
                      disabled={isSubmitting}
                      className="shadow-sm"
                      as="textarea"
                      name="presentation"
                      value={values.presentation}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.presentation && !errors.presentation}
                      isInvalid={touched.presentation && !!errors.presentation}
                      rows={4}
                      placeholder="Utilize este espaço para enviar informações adicionais como, por exemplo, escrever uma carta de apresentação para o recrutador."
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.presentation}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <Form.Group
                    controlId={
                      "formikControl" +
                      (7 + (jobRef?.userApplyQuestions?.length ?? 0))
                    }
                  >
                    <Form.Check
                      disabled={isSubmitting}
                      type="switch"
                      name="newsletter"
                      label={
                        <div className="text-break">
                          {" "}
                          Marque esta opção para receber gratuitamente o resumo
                          das vagas publicadas diariamente na{" "}
                          {siteMetadata.siteName}{" "}
                          <small>
                            {"("}Certifique-se que o endereço de e-mail
                            fornecido acima esteja correto. Basta marcar somente
                            uma vez que você estará inscrito.{")"}
                          </small>
                        </div>
                      }
                      checked={values.newsletter}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Form.Group>
                </div>
              </div>
              <div className="card">
                <div className="bg-warning border-warning card-header">
                  <Form.Group
                    controlId={
                      "formikControl" +
                      (8 + (jobRef?.userApplyQuestions?.length ?? 0))
                    }
                  >
                    <Form.Check
                      disabled={isSubmitting}
                      required
                      type="switch"
                      name="terms"
                      label={
                        <div className="text-break mb-3">
                          Para se candidatar, confirme que está ciente das
                          seguintes orientações: *
                          <br />
                          <br />1{")"} Todo e qualquer processo seletivo tem que
                          ser INTEIRAMENTE GRATUITO E NÃO PODE ENVOLVER QUALQUER
                          TIPO DE PAGAMENTO DIRETO OU INDIRETO, tais como venda
                          de apostilas, exigência de certificados de cursos
                          específicos, instalação de aplicativos, reserva de
                          vagas ou qualquer outra forma de remuneração para o
                          recrutador.
                          <br />
                          <br />2{")"} Caso você receba algum proposta nesse
                          sentido, NÃO PAGUE e nos comunique imediatamente,
                          informando o link da vaga e o email do recrutador.
                        </div>
                      }
                      checked={values.terms}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.terms && !!errors.terms}
                      feedback={<b>Aceite os termos para continuar.</b>}
                      feedbackType="invalid"
                    />
                  </Form.Group>
                </div>
              </div>
              {isSubmitting ? (
                <div className="text-center text-white bg-info border rounded border-0 p-3 p-lg-4">
                  <Spinner animation="grow" size="sm" role="status" /> Enviando
                  informações...
                </div>
              ) : sendSucceed === true ? (
                <div className="text-center text-white bg-success border rounded border-0 p-3 p-lg-4">
                  <p className="mb-0">
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className="fs-5 me-2"
                    />
                    Recebemos a sua solicitação!
                    <br />
                    Agora é só aguardar uma resposta &#128521;.
                  </p>
                </div>
              ) : sendSucceed === false ? (
                <div className="text-center text-white bg-danger border rounded border-0 p-3 p-lg-4">
                  <p className="mb-0">
                    <FontAwesomeIcon
                      icon={faCircleExclamation}
                      className="fs-5 me-2"
                    />
                    Erro ao enviar solicitação. Por favor, tente novamente ou
                    contate-nos.
                  </p>
                </div>
              ) : null}
              {!isValid && formTouched && (
                <div className="text-center text-white bg-danger border rounded border-0 p-3 p-lg-4">
                  <p className="mb-0">
                    <FontAwesomeIcon
                      icon={faCircleExclamation}
                      className="fs-5 me-2"
                    />
                    Um ou mais campos possuem um erro.
                  </p>
                </div>
              )}
              <div className="text-center">
                <Button
                  variant="primary"
                  className="px-5"
                  size="lg"
                  type="submit"
                >
                  Enviar currículo
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>

      <Ad>
        <div
          className="text-center bg-secondary d-flex justify-content-center align-items-center"
          style={{ height: 400, width: "100%" }}
        >
          <h4 className="text-center text-white">
            Espaço para propaganda (Ads)
          </h4>
        </div>
      </Ad>
    </React.Fragment>
  );
};

export default SendResumePage;

export const sendResumePageQuery = graphql`
  query {
    site {
      siteMetadata {
        siteName
      }
    }

    localSearchResumePageJobData {
      publicStoreURL
    }
  }
`;
